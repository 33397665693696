<!--Main Navigation-->
<header>

  <!-- Intro Section -->
  <div class="view jarallax" data-jarallax='{"speed": 0.2}'
    style="background-image: url('https://mdbootstrap.com/img/Photos/Others/gradient2.png'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
    <div class="mask rgba-purple-slight">
      <div class="container h-100 d-flex justify-content-center align-items-center">
        <div class="row pt-5 mt-3">
          <div class="col-md-12 wow fadeIn mb-3">
            <div class="text-center">
              <h1 class="display-4 font-weight-bold mb-5 wow fadeInUp">School Bus is Ready</h1>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</header>
<!--Main Navigation-->

<!--Main Layout-->
<main>

  <div class="container">

    <!--Section: Contact v.2-->
    <section class="section pb-5 wow fadeIn" data-wow-delay="0.3s">

      <!--Section heading-->
      <h2 class="font-weight-bold text-center h1 my-5">Scan the card</h2>


      <div class="row">

        <!--Grid column-->
        <div class="col-md-12 col-xl-9">
          <div>
            <!--Grid row-->
            <div class="row">
              <div class="col-md-12">
                <div class="md-form">
                  <input type="number" [(ngModel)]="name" style="text-align: center;" #myid
                    (input)="sendData($event.target.value)" id="name" class="form-control">
                  <label for="contact-Subject" class=""></label> </div>
              </div>
            </div>
            <!--Grid row-->

          </div>
        </div>
        <!--Grid column-->

      </div>

    </section>
    <!--Section: Contact v.2-->

  </div>





  <div class="container" style="text-align: center;">
    <table mdbTable>
      <thead>
        <tr>
          <th *ngFor="let head of headElements" scope="col">{{head}} </th>
        </tr>
      </thead>
      <tbody>
        <tr mdbTableCol *ngFor="let el of data">
          <th scope="row">{{el.id}}</th>
          <td>{{el.name}}</td>
          <td>{{el.address}}</td>
          <td>{{el.card}}</td>
        </tr>
      </tbody>
    </table>

  </div>

</main>
<!--Main Layout-->


<!--Footer-->
<footer class="page-footer pt-4 mt-4   fixed-bottom   text-center text-md-left mt-5">

  <!--Copyright-->
  <div class="footer-copyright py-3 text-center">
    <div class="container-fluid">
      © 2020 Copyright: <a href="https://devnaif.com/"> DevNaif.com </a>

    </div>
  </div>
  <!--/.Copyright-->

</footer>
<!--/.Footer-->