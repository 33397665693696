import { Component ,ViewChild,ElementRef,AfterViewInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit{

  elements: any = [
    {id: 1, first: 'Mark', last: 'Otto', handle: '@mdo'},
    {id: 2, first: 'Jacob', last: 'Thornton', handle: '@fat'},
    {id: 3, first: 'Larry', last: 'the Bird', handle: '@twitter'},
  ];

  headElements = ['ID', 'Name', 'Address', 'Card'];


  // @ViewChild('name') searchElement: ElementRef;
  @ViewChild('myid', {static: false}) myid: ElementRef;
  data:any[] = [];
  i;
  photo;
  name;
  items;
  student_id;
  title = 'school-bus';
  constructor(private http: HttpClient,) {
//  this.loadCountries()

  }
  ngAfterViewInit(){
    this.myid.nativeElement.focus();
  }
  loadCountries(): void {
    this.http.get('').subscribe((data) => {
      this.items = data['data'];
      console.log(this.data);
      this.myid.nativeElement.focus();       
        // document.getElementById("name").focus();
      (error: any) => {
        console.dir(error);
      }
    });
    
  }

  Change(value: any): void {  
    setTimeout(() => {
      if(value.length == 1){
        this.http.get(`http://admin.bus-school.com/api/storesShow/${value}`).subscribe((data) => {
          this.items = data;
          // for(let item of this.items) {
          //   this.i = item
          //   if(this.i != null){
              this.data.push(this.items)
              this.items = null;
          //   }
          // }
          console.log(this.items);
          console.log(value);
          this.name = null;
          this.myid.nativeElement.focus();       
            // document.getElementById("name").focus();
          (error: any) => {
            console.dir(error);
            
          }
          
        });
        this.name = null;
        this.myid.nativeElement.focus();
        this.items = null;
      }
  }, 500);
  }


  sendData(value: any): void{
    setTimeout(() => {
      if(value.length == 10){
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    let postData =  {
     card: value
 }
    this.http.post("http://admin.bus-school.com/api/students", postData,{observe: 'response'})
      .subscribe((res) => {
        // console.log(res['body'][0][0]);
        this.items = res['body'][0][0];
        if(this.items != null){
        this.data.push(this.items)
        console.log(this.items.id)
        this.sendActiv(this.items.id)
        }
        this.name = null;
          this.myid.nativeElement.focus(); 
          
       }, error => {
        console.log(error);
        
      });
      this.name = null;
        this.myid.nativeElement.focus();
        this.items = null;
    }
  }, 500);
  }

  sendActiv(value: any): void{
    setTimeout(() => {
  
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json' );

    let postData =  {
      student_id: value
 }
    this.http.post("http://admin.bus-school.com/api/activity", postData,{observe: 'response'})
      .subscribe((res) => {
        console.log(res);  
       }, error => {
        console.log(error);
        
      });
  }, 500);
  }
}
